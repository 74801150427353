import Vue from 'vue';
import { vsprintf } from 'sprintf-js';

const CommonModule = {
	get_params_array: (value: any): any => {
		if(!value)
		{
			return [];
		}

		if(typeof(value) === 'string')
		{
			return [value];
		}

		return value;
	},
	sprintf: (format: string, args: any[]): string => {
		return vsprintf(format, args);
	}
};

const Plugin = {
	install(_Vue: typeof Vue)
	{
		_Vue.prototype._ = CommonModule;
	}
};

Vue.use(Plugin);