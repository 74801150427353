import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 시작화면
	 */
	{
		name: 'home',
		path: '/',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/Home.vue')
	},

	/**
	 * @brief 호반on링크
	 */
	{
		name: 'hoban',
		path: '/HobanON',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/HobanLink.vue')
	},

	/**
	 * @brief 404 Not Found
	 */
	{
		name: 'not-found',
		path: '*',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "not-found" */ '@/views/NotFound.vue')
	}
];

export default routes;
